<template>    
  <div>
    <v-container fluid>
      <v-toolbar flat dense class="tc-title">
            <v-toolbar-title>Question Bank {{user_type}}</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>

            <v-btn class="tooltip" color="primary" dark fab small @click="addquestions" >
              <v-icon>mdi-plus</v-icon>
               <span class="tooltiptext">Add Question </span>
            </v-btn>


             <!-- <v-tooltip>
      <template v-slot:activator="{ on }">
        <v-icon
          color="primary"
          dark
          class="logi mx-2"
          fab small @click="addquestions"
         
          v-on="on"
        >
          mdi-plus
        </v-icon>
      </template>
      <span>Add Question</span>
    </v-tooltip> -->
            
            <v-btn class="tooltip" color="primary" dark  v-if="user_type!='Student'" fab small @click="approvequestions">
              <v-icon>mdi-check</v-icon>
             <span  class="tooltiptext">Approve</span>
              </v-btn>
       
       
        <!-- <v-tooltip>
      <template v-slot:activator="{ on }">
        <v-icon
          color="primary"
          dark
         
          fab small @click="approvequestions"
         
          v-on="on"
        >
          mdi-check
        </v-icon>
      </template>
      <span data-toggle="tooltip" data-original-title="Edit">approve question</span>
    </v-tooltip> -->
       
       
       
       
       
       
       
       
       
        </v-toolbar>
    <v-card>
      <v-card-text>
        <v-row align="center">
          <v-col class="d-flex" cols="12" sm="3">
            <v-select
              :items="company_list"
              item-value="id"
              item-text="name"
              label="Choose Company"
              v-model="selected_company"
              ></v-select>
          </v-col>
          <v-col class="d-flex" cols="12" sm="3">
            <v-select
                :items="round_list"
                item-value="id"
                item-text="name"
                label="Round"
                v-model="selected_round"
              ></v-select>
          </v-col>

          <v-col class="d-flex" cols="12" sm="3">
            <v-select
              :items="type_list"
              item-value="id"
              item-text="name"
              v-model="selected_type"
              label="Question Type"
            ></v-select>
          </v-col>

          <v-col class="d-flex" cols="12" sm="3">
            <v-select
                :items="status_list"
                item-value="id"
                item-text="name"
                label="Question Status"
                v-model="selected_status"
              ></v-select>
          </v-col>
      </v-row>
      <v-card-actions>
          <v-btn color="primary" @click="fetchquestions" class="mb-2 logi" >PROCEED</v-btn>
      </v-card-actions>
  </v-card-text>
</v-card> 
  <template v-if="this.question_list!=null">
    <template v-if="this.question_list.length==0">
      <v-toolbar><v-toolbar-title>No Questions Available</v-toolbar-title></v-toolbar>
    </template>
    <template v-else>
    <v-simple-table responsive>
      <tr>
        <th class="text-left">Company</th>
        <th class="text-left">Company Round</th>
        <th class="text-left">Question Statement</th>
        <th class="text-left">Question Answer</th>
        <th class="text-left">Question Type</th>
        <th class="text-left">View Question</th>
      <!--  <th class="text-left">Actions</th>-->
      </tr>
      <tbody>
        <tr v-for="que in this.question_list" :key="que[0].id">
          <td>
            {{ que[0].question_company}}
          </td>
          <td>
            {{ que[0].question_company_round}}
          </td>
          <td>
            {{ que[0].question_stmt}}
          </td>
          <td>
            {{ que[0].question_answer}}
          </td>

          
          <td>
            {{ que[0].question_type}}
          </td>
          
          <td>
            <v-btn
              slot="activator"
              small
              color="primary"
              @click="viewItem(que[0])"
            >
              View More
            </v-btn>
          </td>
       <!--   <td>
            <v-avatar style="" size="25" class="edit-avatar">
                <v-icon class="edit-v-icon mr-2" @click="editItem(que[0])">edit</v-icon>
            </v-avatar>
            <v-avatar style="" size="25" class="delete-avatar">
                <v-icon class="delete-v-icon mr-2" @click="deleteItem(que[0].id)">delete</v-icon>
            </v-avatar>                                
          </td>-->
      </tr>
    </tbody>
    </v-simple-table>
    </template>
    
    <v-layout row justify-center>
      <v-dialog v-model="dialog" persistent max-width="900px" lazy absolute>
        <v-card>
          <template v-if="this.selectedquestion!=null && this.selectfor=='view'">      
            <v-card-title
              class="headline grey lighten-2"
              primary-title >
              {{this.selectedquestion.question_stmt}} 
        
            </v-card-title>
            <v-card-text>
              {{this.selectedquestion.question_answer}}
            </v-card-text>
            <template v-if="this.selectedquestion.question_type === 'single word'">
              <div v-for="opt in this.selectedquestion.option_list" :key="opt.id">
                <v-card-text>
                  <template v-if="opt.iscorrect===true">
                    <v-checkbox readonly v-model="opt.iscorrect" v-bind:label="`${opt.option_name} ] ${opt.option_value}`"></v-checkbox>
                  </template>
                  <template v-if="opt.iscorrect===false">
                    <v-checkbox readonly v-model="opt.iscorrect" v-bind:label="`${opt.option_name} ] ${opt.option_value}`"></v-checkbox>
                  </template>
                </v-card-text>
              </div>
            </template>
            <template v-if="this.selectedquestion.question_type === 'Image'">
              <template v-if=this.selectedquestion.quefile>  
               <img v-bind:src=this.selectedquestion.quefile>
              </template>
            </template>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                @click="dialog = false"
              >
                close
              </v-btn>
            </v-card-actions>
          </template>
          <template v-if="this.selectedquestion!=null && this.selectfor=='edit'">      
            
            <v-card-title
              class="headline grey lighten-2"
              primary-title >
              Edit Question 
        
            </v-card-title>
            
            <v-row >

            <v-col cols="1" sm="1">
            </v-col>
            <v-col cols="12" sm="10">
            <div  v-show="this.selectedquestion.question_type !='Image'">
                <v-row >
                    <v-col cols="12" sm="10">
                        <v-text-field outlined label="Question Statement" required v-model="this.selQuestionStmt"></v-text-field>
                    </v-col> 
                </v-row>
            </div>
            <div v-show="this.selectedquestion.question_type === 'single word'">
                <div id="app">
                    <div v-for="(input, index) in inputs">
                        <v-col cols="12" sm="8">
                        <v-label>Option {{index+1}}</v-label>
                            <v-row>
                                
                                <v-checkbox input type="checkbox" v-model="input.value">  </v-checkbox>

                                <v-text-field type="text" outlined v-model="input.text"> </v-text-field>
                                
                        <v-col cols="1" sm="1"> </v-col> 
                                <v-btn color="primary" @click="deleteRow(index)"><v-icon>mdi-delete</v-icon></v-btn>
                                
                            </v-row> 
                        
                        </v-col>  
                    </div>
                    <!-- <v-icon color="primary" class="add-v-icon mr-2" @click="addRow">add </v-icon>Add Option -->
                    <div v-show="this.optionsize <= 4">
                        <v-btn color="primary" @click="addRow">ADD Options</v-btn>
                    </div>
                </div>
            </div> 
            <div v-show="this.selectedquestion.question_type === 'Descriptive'">
                <v-row >
                    <v-col cols="10" sm="10">
                        <v-text-field outlined label="Question Answer" required v-model="this.selQuestionAnswer"></v-text-field>
                    </v-col> 
                </v-row>
            </div>
            <div v-show="this.selectedquestion.question_type === 'Image'">
                <v-col cols="10" sm="10">
                    <v-card-text>
                        <v-file-input
                            accept=".jpg,"
                            label="Choose image (max size: 2MB)"
                            outlined
                            show-size
                            v-model="QuestionImageFile"
                        >
                        </v-file-input>
                    </v-card-text>
                </v-col>          
            </div>
            </v-col> 
            <v-col cols="1" sm="1">
            </v-col>
            </v-row>          
            <v-divider></v-divider>
            <v-card-actions>
              
              <v-btn
                color="primary"
                @click="dialog = false"
              >
                close
              </v-btn>
             <v-spacer></v-spacer>
              <v-btn
                color="primary"
                @click="editquestions"
              >
                Edit
              </v-btn>
            </v-card-actions>
          </template>
        </v-card>
      </v-dialog>
    </v-layout>
  </template>
    </v-container>     
  </div>
</template>

<script>
import axios from "axios";
import Template from '../AdminMaster/Template.vue';
export default {
  components: { Template },
  data: () => ({
    snackbar_msg: "",
    snackbar: false,
    color: "",
    org: null,
    search:'',
    edit_btn_load:false,
    selected_company:'',
    selected_round:'',
    selected_status:'',
    selected_type:'',
    question_data:'',
    questiondata:'',
    dialog: false,
    selected:'',
    selected_all:'',
    company_list:null,
    round_list:null,
    type_list:null,
    status_list:null,
    question_list:null,
    selectedquestion:null,
    selectfor:'',
    optionsize:0,
    inputs: [],
    selQuestionStmt:'',
    selQuestionAnswer:'',
    selQuestionId:'',

   editedIndex: -1,
      editedItem: {
        company: '',
        round: '',
        type: '',
        status: '',
      },
      defaultItem: {
       company: '',
        round: '',
        type: '',
        status: '',
      },
      user_type:null
  }),
   computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
    },
 
  methods: {
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
                addRow() {

                this.inputs.push({
                    value: '',
                    text: '',
                    id:'0',
                })
                this.optionsize++
            },
            deleteRow(index) {
                this.inputs.splice(index,1)
                this.optionsize--
            },
    viewItem (item) {
        this.selectedquestion = Object.assign({}, item)
        this.dialog = true
        this.selectfor="view"
      },
    editItem (item) {
      //console.log("in edititem");
        this.selectedquestion = Object.assign({}, item)
        this.selectfor="edit"
        this.inputs=[]
        this.optionsize=0
        this.selQuestionId=this.selectedquestion.id
        this.selQuestionStmt=this.selectedquestion.question_stmt
        this.selQuestionAnswer=this.selectedquestion.question_answer
         //console.log(this.selectedquestion);
        for(let x in this.selectedquestion.option_list){
          this.inputs.push({
              value: this.selectedquestion.option_list[x].iscorrect,
              text: this.selectedquestion.option_list[x].option_value,
              id: this.selectedquestion.option_list[x].id,
          })
          this.optionsize++
             
        }
        this.dialog = true
      },
close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    updateEmployee (value,authorityid) {
      this.selected_emp.push(value);
      this.selected_emp.push(authorityid);
    },
    addquestions(){
      this.$router.push("/student-addquestion")
    },
    approvequestions(){
      this.$router.push("/student-approvequestion")
    },
   fetchquestions()
   {
     const data = {
       company_id:this.selected_company,
       company_round_id:this.selected_round,
       question_type_id:this.selected_type,
       question_status_id:this.selected_status
      };
       axios
        .post("/LearnerData/FetchQuestionBank", data)
        .then(res => {
          //window.console.log(res);
          if (res.data.msg == "200") {
            this.questiondata = res.data.question_list;
            this.question_list = res.data.question_list;
            //this.courseauthoritylevels=res.data.courseauthoritylevels;
          } else {
            this.message = res.data.msg;
          }
        })
        .catch(error => {
          //window.console.log("error:" + error);
        })
        .finally(() => (this.fetch_org_load = false));
   },
  editquestions()
  {
    console.log(" ans : "+this.selQuestionAnswer);
    const data = {
      "questionobject":this.selectedquestion,
      optionlist:this.inputs,  
      question_id:this.selectedquestion.id,
      question_answer:this.selQuestionAnswer,
      question_stmt:this.selQuestionStmt,
      question_type:this.selectedquestion.question_type.name,
    };
       axios
        .post("/LearnerData/EditQuestionBank", data)
        .then(res => {
         // window.console.log(res);
         if (res.data.code == "200") {
                             this.dialog = false;
                              this.showSnackbar("#4caf50",res.data.msg);
          } else {
            this.message = res.data.msg;
          }
        })
        .catch(error => {
          //window.console.log("error:" + error);
        })
        .finally(() => (this.fetch_org_load = false));
   }
  },
 
  mounted() {
    axios
      .post("/LearnerData/QuestionBank")
      .then(res => {
          this.company_list = res.data.company_list
          this.round_list = res.data.company_round_list
          this.type_list = res.data.question_type_list
          this.status_list = res.data.question_status_list
          this.user_type=res.data.userType
          this.company_list.push({
                    name: 'ALL',
                    text: ''
          })
          this.round_list.push({
                    name: 'ALL',
                    text: ''
          })
          this.type_list.push({
                    name: 'ALL',
                    text: ''
          })
          this.status_list.push({
                    name: 'ALL',
                    text: ''
          })
          this.selected_company="ALL"
          this.selected_round="ALL"
          this.selected_type="ALL"
          this.selected_status="ALL"
      })
      .catch(error => {
        this.showSnackbar("#b71c1c", "Something went wrong!!!");
      })
      .finally(() => {

      });
  }
};
</script>
<style scoped>

.nav-arrow-left{
        margin-right: 5px;
        text-decoration: none;
        color: #FFF;
    }
    .nav-arrow-right{
        margin: 5px;
        text-decoration: none;
        color: rgb(3, 55, 167);
    }
    .left-arrow{
        color: #FFF;
    }
    .right-arrow{
        color: rgb(3, 55, 167);
    }
.ec-title{
        background-image: linear-gradient(-100deg, #f4faba, #d64907);
        color: #FFF;
    }
    .c-title{
        background-image: linear-gradient(-90deg, #befcf7, #03948d);
        color: #FFF;
    }
    .tc-title{
        background-image: linear-gradient(-90deg, #FFF, #057996);
        color: #FFF;
        border-radius: 3px;
    }
    .logi{
        background-image: linear-gradient(-100deg, #8aa5f7, #063bdb);
        color: #FFF;
    }
    .card-title{
         background-image: linear-gradient(-90deg, #8edffd, #034c70);
        color: #FFF;
    }
    .del-card-title{
         background-image: linear-gradient(-90deg, #fde7e7, #ac0404);
        color: #FFF;
    }
    .lbl{
        color: #1705e6;
    }


    .tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: lightblue;
  color: #0000ff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>
